<script>
import { required } from "vuelidate/lib/validators";
export default {
  props: ["userData"],
  data() {
    return {
      userEditForm: {
        name: "",
        lastName: "",
        balance: "",
        verification: "",
      },
    };
  },
  mounted() {
    if (this.userData) {
      this.parseUserForm(this.userData);
    }
  },
  validations: {
    userEditForm: {
      name: { required },
    },
  },
  methods: {
    updateUser() {
      console.log("Update", this.userEditForm);
    },
    parseUserForm(data) {
      this.userEditForm = {
        name: data.name,
        lastName: data.lastName,
        image_path: null,
        verification: data.verification === "1" ? true : false,
      };
    },
  },
  watch: {
    userData(val) {
      this.parseUserForm(val);
    },
  },
};
</script>

<template>
  <div>
    <div class="card">
      <div class="card-body" style="padding-bottom:100px;">
        <h4 class="card-title my-4">Edit User</h4>
        <form class="needs-validation" @submit.prevent>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="validationCustom02">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="userEditForm.name"
                  placeholder="First Name Here!"
                />
                <div class="invalid-feedback">
                  <span>First name is required.</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="validationCustom02">Last Name</label>
                <input
                  type="text"
                  v-model="userEditForm.lastName"
                  class="form-control"
                  placeholder="Last Name Here!"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Verification</label>
                <b-form-checkbox
                  name="check-button"
                  v-model="userEditForm.verification"
                  switch
                  style="margin:10px;"
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <FormulateInput
            type="image"
            name="headshot"
            v-model="userEditForm.image_path"
            label="Change user profile pic"
            help="Select a png, jpg or gif to upload."
            validation="mime:image/jpeg,image/png,image/gif"
          />
          <div class="mt-4 text-center my-4">
            <button
              style="margin-top:50px"
              class="btn btn-primary w-md waves-effect waves-light float-right"
              type="button"
              @click="updateUser()"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
